<template>
  <v-data-table
    item-key="id"
    :loading="isLoading"
    :headers="displayedHeader"
    :items="additionalCostListItem"
    :server-items-length="totalItems"
    :options.sync="pagination"
    :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
    @click:row="rowClicked"
  >
    <template v-slot:[`item.createdAt`]="{ item }">
      <span>
        {{item.createdBy}}
        {{dateFormat(item.createdAt)}}
      </span>
    </template>
    <template v-slot:[`item.reason`]="{ item }">
      <span>{{item.reason}}</span>
    </template>
    <template v-slot:[`item.needConfirm`]="{ item }">
      <span>{{item.needConfirm ? ' Kena Pajak' : ' Tidak Kena Pajak'}}</span>
    </template>
    <template v-slot:[`item.isActive`]={item}>
      <v-select
        class="mt-5 pa-0 caption"
        style="width: 8rem"
        outlined
        dense
        item-value="value"
        item-text="key"
        v-model="item.isActive"
        @click.stop.prevent
        :items="itemStatus"
        :disabled="!userAccess.canUpdate"
        :loading="item.isLoading"
        @change="onChangeIsActive(item, $event)"
      >
      </v-select>
    </template>
    <template v-slot:[`footer.page-text`]="props">
      <span>
        {{$_strings.additionalCost.ADDITIONAL_COST_DATABASE}}
        <span v-if="additionalCostListItem.length">
          {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
        </span>
      </span>
    </template>
  </v-data-table>
</template>

<script>

import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

export default {
  name: 'list-additional-cost',
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.additionalCost.ITEM,
          value: 'reason',
        },
        {
          text: this.$_strings.additionalCost.VALUE,
          value: 'description',
        },
        {
          text: this.$_strings.additionalCost.ORACLE_INTERNAL,
          value: 'oracleCostInternalDescription',
        },
        {
          text: this.$_strings.additionalCost.ORACLE_EXTERNAL,
          value: 'oracleCostExternalDescription',
        },
        {
          text: this.$_strings.additionalCost.TAX,
          value: 'needConfirm',
        },
        {
          text: this.$_strings.additionalCost.CREATED_AT,
          value: 'createdAt',
        },
        {
          text: this.$_strings.vaccine.STATUS,
          value: 'isActive',
        },
      ],
      additionalCostListItem: [],
      pagination: this.defaultPagination(),
      totalItems: 0,
      itemStatus: [
        {
          key: 'Aktif',
          value: true,
        },
        {
          key: 'Tidak Aktif',
          value: false,
        },
      ],
    };
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        this.fetchAdditionalCostList();
      },
      deep: true,
    },
  },
  methods: {
    defaultPagination,
    handlerPagination,
    handleSortBy,
    skipEmptyStringObject,
    dateFormat,
    toggleLoading(boolean) {
      if (this.additionalCostListItem.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    rowClicked(item) {
      this.$emit('showDialogAdditionalCost', item);
    },
    async onChangeIsActive(item, event) {
      const form = {
        ...item,
        isActive: event,
      };
      this.$set(item, 'isLoading', true);
      try {
        await this.$_services.masterData.additionalAddEdit(form, item.id);
        this.$dialog.notify.success(this.$_strings.additionalCost.SUCCES_EDIT_STATUS);
      } catch {
        this.$nextTick(() => {
          item.isActive = !event;
        });
      } finally {
        this.$delete(item, 'isLoading');
      }
    },
    async fetchAdditionalCostList() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = skipEmptyStringObject({
        type: 'qIn(COST)',
        page: page - 1,
        size: itemsPerPage,
        ...this.filters,
      });
      if (this.filters.name) filters.name = `qLike(${filters.name})`;
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        filters.sort = `${sortBy[0].includes('reason') ? 'name' : sortBy[0]},${isDesc}`;
      }
      try {
        this.toggleLoading(true);
        const result = await this.$_services.additionalCost.getListAdditionalCost(this.skipEmptyStringObject(filters));
        this.additionalCostListItem = result.data.contents;
        this.totalItems = result.data.totalData;
      } finally {
        this.toggleLoading(false);
      }
    },
  },
};
</script>
