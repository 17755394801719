<template>
  <v-menu
    :close-on-content-click="closeOnContentClick"
    bottom
    width="300"
    offset-y
    z-index="1"
    allow-overflow
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        hide-details
        height="10"
        class="caption"
        prepend-inner-icon="mdi-filter"
        placeholder="Filter Berdasarkan"
        outlined
        dense
        readonly
        v-bind="attrs"
        @click="closeOnContentClick = false;"
        v-on="on"
      ></v-text-field>
    </template>
    <v-list
      width="300"
      min-height="150"
      max-height="400"
      class="overflow-auto"
    >
      <section>
        <v-list-item>
          <v-list-item-title class="caption font-weight-bold">{{$_strings.additionalCost.ORACLE_INTERNAL}}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pt-0 mt-0">
            <v-text-field
              dense
              outlined
              class="caption"
              v-model="filters.oracleCostInternalDescription"
              hide-details
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="caption font-weight-bold">{{$_strings.additionalCost.ORACLE_EXTERNAL}}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pt-0 mt-0">
            <v-text-field
              dense
              outlined
              class="caption"
              v-model="filters.oracleCostExternalDescription"
              hide-details
            />
          </v-list-item-content>
        </v-list-item>
        <div v-for="item in listItems" :key="item.key" class="ml-4">
          <span class="caption font-weight-bold">{{item.title}}</span>
          <common-auto-complete-items
            v-if="item.items === 'oracleList'"
            ref="refListOracleCost"
            type="listOracleCost"
            searchName="name"
            item-value="id"
            item-text="name"
            class="caption mb-4 mr-4"
            clearable
            v-model="filters[item.vModel]"
            :filter="filterListOracleCost"
            :items="items[item.items]"
            dense
            hide-details
            flat
            @updateItems="updateListOracle"
          />
          <v-select
            v-else
            :items="items[item.items]"
            :menu-props="{ bottom: true, offsetY: true }"
            v-model="filters[item.vModel]"
            class="caption mb-4 mr-4"
            dense
            hide-details
            flat
          />
        </div>
      </section>
      <section class="pl-2 pr-2">
        <v-btn
          depressed
          color="primary"
          block
          @click="setFilter"
        >
          {{$_strings.common.APPLY}}
        </v-btn>
        <v-btn
          depressed
          class="mt-2 white--text"
          color="red"
          block
          @click="closeOnContentClick = true"
        >
          {{$_strings.common.CLOSE}}
        </v-btn>
      </section>
    </v-list>
  </v-menu>
</template>

<script>
import { filterDuplicateValues } from '@/helper/commonHelpers';

export default {
  name: 'fiter-oracle-cost',
  data() {
    return {
      closeOnContentClick: false,
      filters: {
        needConfirm: this.$route.query.needConfirm || '',
        isActive: this.$route.query.isActive || '',
        oracleCostInternalDescription: this.$route.query.oracleCostInternalDescription || '',
        oracleCostExternalDescription: this.$route.query.oracleCostExternalDescription || '',
      },
      filterListOracleCost: {
        page: 0,
        size: 25,
        search: '',
      },
      items: {
        tax: this.$_strings.additionalCost.FILTER_LIST,
        status: [
          {
            text: 'Semua Status',
            value: '',
          },
          {
            text: 'Aktif',
            value: 'qistrue',
          },
          {
            text: 'Tidak Aktif',
            value: 'qisfalse',
          },
        ],
        // oracleList: [
        //   {
        //     name: 'Semua Biaya Oracle',
        //     code: '',
        //     id: '',
        //   },
        // ],
      },
      listItems: [
        {
          key: 1,
          title: 'Pajak',
          vModel: 'needConfirm',
          items: 'tax',
        },
        {
          key: 2,
          title: 'Status',
          vModel: 'isActive',
          items: 'status',
        },
      ],
    };
  },
  methods: {
    filterDuplicateValues,
    setFilter() {
      this.closeOnContentClick = true;
      this.$emit('setNewFilters', this.filters);
    },
    // updateListOracle(items) {
    //   const _items = items.map((item) => ({
    //     id: item.id,
    //     code: item.code,
    //     name: `${item.code} - ${item.name}`,
    //   }));
    //   this.items.oracleList = this.filterDuplicateValues([...this.items.oracleList, ..._items]);
    // },
  },
};
</script>
